















import { Component, Inject, InjectReactive, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { ITheoryExam } from "@/interfaces/Exam/ITheoryExam";
import ExamList from "@/views/Exam/TheoryExam/ExamList.vue";
import ExamMixin from "@/mixins/ExamMixin";
import { mixins } from "vue-class-component";
import CustomSlider from "@/components/Slider/CustomSlider.vue";

const TheoryExamFilterModule = namespace("theory-exam/localFilter");

@Component({
  components: {
    ExamList,
    CustomSlider,
  },
})
export default class TheoryExamList extends mixins(ExamMixin) {
  public name = "TheoryExamList";

  private selectedExam?: ITheoryExam | null = {};

  @Prop({ type: Boolean, default: () => false })
  public archived!: any;

  @Prop({ type: String, default: () => "" })
  public filterTheoryExam!: string;

  @TheoryExamFilterModule.State("filter")
  public theoryExamLocalFilter: any;

  @TheoryExamFilterModule.Mutation("SET_LOCAL_FILTER")
  public setTheoryExamLocalFilter!: (payload: Record<any, any>) => void;

  @Inject("theoryExams")
  private findAllAction!: (filter: any) => Promise<void>;

  @InjectReactive("theoryExamsData")
  private exams!: Array<any>;

  @InjectReactive("theoryExamsTotalRow")
  private totalRows!: number;

  @InjectReactive("theoryExamsLoading")
  private loading!: boolean;

  @Inject("readyTheoryExam")
  private ready!: (theoryExamId: number) => Promise<void>;

  @InjectReactive("readyTheoryExamLoading")
  private readyTheoryExamLoading!: boolean;

  public offset = 0;

  public currentPerPage = 7;

  public created() {
    if (this.theoryExamLocalFilter instanceof Object) {
      this.$emit("update:archived", this.theoryExamLocalFilter.archived);
    } else {
      this.onCurrentPageChange(this.offset);
    }
  }

  public async loadExams(): Promise<void> {
    this.onCurrentPageChange(this.offset);
  }

  @Watch("archived")
  private async onArchivedChange(archived: any): Promise<void> {
    this.setTheoryExamLocalFilter({
      archived,
    });
    this.onCurrentPageChange(0);
  }

  private onClick(exam: ITheoryExam, parentSelected = false): void {
    this.selectedExam = exam;
    this.$emit("on-click", this.selectedExam, parentSelected);
  }

  private ondDblclick(exam: ITheoryExam): void {
    this.selectedExam = exam;
    this.$emit("on-dblclick", this.selectedExam);
  }

  protected async onReady(exam: any): Promise<void> {
    if (this.hasNotPermissionWrite) return;
    await this.ready(exam.id);

    exam.ready = !exam.ready;
  }

  private onCurrentPageChange(offset: any) {
    this.offset = offset;
    this.findAllAction({
      offset: offset,
      limit: 7,
      searchTerm: "",
      archived: this.archived,
    });
  }

  protected get filteredExams() {
    if (this.filterTheoryExam) {
      const filter = this.filterTheoryExam.toLowerCase();
      return this.exams.filter((courseAndTheoryLesson: any) => {
        return (
          courseAndTheoryLesson.proctor?.toLowerCase()?.indexOf(filter) > -1 ||
          courseAndTheoryLesson.notes?.toLowerCase()?.indexOf(filter) > -1 ||
          courseAndTheoryLesson.testingOrganization?.name?.toLowerCase()?.indexOf(filter) > -1 ||
          courseAndTheoryLesson.date?.toLowerCase()?.indexOf(filter) > -1
        );
      });
    }
    return this.exams;
  }

  private get isLoading() {
    return this.loading || this.readyTheoryExamLoading;
  }
}
