







import { Component, Prop, Vue } from "vue-property-decorator";
import TheoryExamCreate from "@/views/Exam/TheoryExam/Exam/TheoryExamCreate.vue";
import TheoryExamUpdate from "@/views/Exam/TheoryExam/Exam/TheoryExamUpdate.vue";
import { PropType } from "vue";
import { ExamModeEnum } from "@/enums/ExamModeEnum";

@Component({
  components: {
    TheoryExamUpdate,
    TheoryExamCreate,
  },
})
export default class TheoryExamInfo extends Vue {
  public name = "TheoryExamInfo";

  @Prop()
  public exam!: any;

  @Prop({ required: true, type: Number as PropType<ExamModeEnum> })
  public examMode!: ExamModeEnum;

  @Prop({ required: false, type: Boolean, default: () => false })
  public loading!: boolean;

  private onClose(): void {
    this.$emit("on-close");
  }

  private get editMode() {
    return this.examMode === ExamModeEnum.edit;
  }
}
