


































import { Component, Inject, InjectReactive, Prop, ProvideReactive } from "vue-property-decorator";
import TheoryExamParticipantsList from "@/views/Exam/TheoryExam/Participants/Edit/TheoryExamParticipantsList.vue";
import TheoryExamStudentsSelect from "@/views/Exam/TheoryExam/Participants/Add/TheoryExamStudentsSelect.vue";
import FuncMixins from "@/mixins/FuncMixins";
import TemplateEmailForm from "@/components/TemplateEmailForm.vue";
import { mixins } from "vue-class-component";

@Component({
  components: { TheoryExamStudentsSelect, TheoryExamParticipantsList, TemplateEmailForm },
})
export default class TheoryExamParticipants extends mixins(FuncMixins) {
  public name = "TheoryExamParticipants";

  @Prop()
  private examId!: any;

  @Prop()
  private exam!: any;

  @Prop({ type: String })
  private form!: any;

  @ProvideReactive("filterTheoryExam")
  public filterTheoryExam = "";

  @InjectReactive("theoryExamLoading")
  public theoryExamLoading!: boolean;

  @Inject("openParticipants")
  protected openParticipants: any;

  @Inject("openStudentsSelect")
  protected openStudentsSelect: any;

  @InjectReactive("createDeleteOrUpdateLoadingTheoryExam")
  protected createDeleteOrUpdateLoadingTheoryExam!: boolean;

  public reduceColumns = false;
  public allStudents: Array<any> = [];

  protected get isParticipants(): boolean {
    return this.form === "participants";
  }

  protected onFilterTheoryExam(value: string) {
    this.debouncer(() => {
      this.filterTheoryExam = value;
    });
  }

  public onReduceColumns(flag: boolean) {
    this.reduceColumns = flag;
  }

  public onSetRecipients(recipients: any) {
    const { allStudents } = recipients;
    this.allStudents = allStudents;
  }

  public get recipients() {
    return this.allStudents || [];
  }

  private get isLoading() {
    return this.theoryExamLoading || this.createDeleteOrUpdateLoadingTheoryExam;
  }
}
