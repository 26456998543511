var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.loading,"rounded":"sm"}},[_c('fsc-card',{staticStyle:{"height":"calc(100vh - 400px)"}},[_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.exam_date"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.date},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('Datepicker',{attrs:{"input-class":{ 'is-invalid': invalid },"label-no-date-selected":_vm.$t('calendar.label_no_date'),"label-help":_vm.$t('calendar.label_help'),"disabled":_vm.hasNotPermissionWrite || _vm.isBooked},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.exam_time"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.time},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('TimePicker',{class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite || _vm.isBooked},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"lg":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.exam_duration"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.durationInMinutes},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","min":"1","disabled":_vm.hasNotPermissionWrite || _vm.isBooked},model:{value:(_vm.durationInMinutes),callback:function ($$v) {_vm.durationInMinutes=$$v},expression:"durationInMinutes"}})}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"lg":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.exam_organisation"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.testingOrganization},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.allTestingOrganizations,"track-by":"id","label":"name","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.testingOrganization),callback:function ($$v) {_vm.testingOrganization=$$v},expression:"testingOrganization"}})}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"lg":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("general.place", 2))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.availableSlots},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","min":"1","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.availableSlots),callback:function ($$v) {_vm.availableSlots=$$v},expression:"availableSlots"}})}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"lg":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.proctor"))+" ")]),_c('b-input',{attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.proctor),callback:function ($$v) {_vm.proctor=$$v},expression:"proctor"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"lg":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.instructors"))+" ")]),_c('fsc-multiselect',{attrs:{"options":_vm.users,"multiple":true,"label":"initials","track-by":"id","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"lg":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("calendar.note"))+" ")]),_c('b-textarea',{attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"btn-container mr-0"},[_c('abort-button',{staticClass:"fsc-btn-border",attrs:{"label":""},on:{"click":function($event){return _vm.onClose()}}}),_c('save-button',{staticClass:"ml-2 fsc-btn-border",attrs:{"disabled":_vm.loading || _vm.hasNotPermissionWrite,"label":_vm.$t('general.save'),"loading":_vm.loading},on:{"click":function($event){return _vm.onSubmit()}}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }