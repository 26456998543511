






import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import TheoryExamForm from "@/views/Exam/TheoryExam/Exam/TheoryExamForm.vue";

@Component({
  components: {
    TheoryExamForm,
  },
})
export default class TheoryExamUpdate extends Vue {
  public name = "TheoryExamUpdate";

  @Prop()
  private exam!: any;

  @Prop()
  private loading: any;

  @Inject("updateTheoryExam")
  protected updateTheoryExam: any;

  private onSubmit(exam: any): void {
    this.updateTheoryExam(exam);
  }

  private onClose(): void {
    this.$emit("on-close");
  }
}
