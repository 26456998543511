

























































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import FscCard from "@/components/Card/FscCard.vue";
import Validation from "@/components/Validation.vue";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import Datepicker from "@/components/Datepicker.vue";
import TimePicker from "@/components/TimePicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { ITheoryExam } from "@/interfaces/Exam/ITheoryExam";
import ExamMixin from "@/mixins/ExamMixin";
import DefaultInstructorSorting from "@/constants/DefaultInstructorSorting";

const UserModule = namespace("instructor");
const TuvModule = namespace("tuv");
const TestingOrganizationsModule = namespace("testing-organizations");

@Component({
  components: {
    SaveButton,
    AbortButton,
    TimePicker,
    Datepicker,
    Validation,
    FscCard,
  },
})
export default class TheoryExamForm extends mixins(ViewSwitchMixin, ExamMixin) {
  public name = "TheoryExamForm";

  @Validate({ required })
  private availableSlots?: number | null = null;
  @Validate({ required })
  private date?: Date | null = null;
  @Validate({ required })
  private durationInMinutes: number | null = null;
  private notes?: string | null = null;
  @Validate({ required })
  private time?: Date | null = null;
  private proctor?: string | null = null;
  private user?: [] | null = null;
  @Validate({ required })
  private testingOrganization?: any | null = null;

  @Prop({ default: null })
  private exam!: ITheoryExam;

  @Prop()
  private loading!: any;

  @TuvModule.Action("findAll")
  public findAllTuvServicesAction: any;

  @TuvModule.Getter("getDataList")
  public tuvServices: any;

  @UserModule.Action("filter")
  public filterAction: any;

  @UserModule.Getter("getDataList")
  public users: any;

  @TestingOrganizationsModule.Action("filter")
  public findAllTestingOrganizations: any;

  @TestingOrganizationsModule.Getter("getDataList")
  public allTestingOrganizations: any;

  public mounted(): void {
    this.findAllTestingOrganizations({
      resource: "testing-organizations",
      filter: { archived: false },
    });
    this.filterAction({ resource: "instructor", filter: { archived: false, ...DefaultInstructorSorting } });
  }

  @Watch("exam", { deep: true, immediate: true })
  private onExamChange(exam: any): void {
    if (exam) {
      this.availableSlots = exam.availableSlots;
      this.date = exam.date;
      this.notes = exam.notes;
      this.durationInMinutes = exam.durationInMinutes;
      this.time = exam.time;
      this.testingOrganization = exam.testingOrganization;
      this.proctor = exam.proctor;
      this.user = exam.users;
    }
  }

  public validate(): boolean {
    this.$v.$touch();
    return this.$v.$invalid;
  }

  private onSubmit(): void {
    if (this.hasNotPermissionWrite) return;

    if (this.validate()) {
      return;
    }

    const exam = {
      id: 0,
      availableSlots: this.availableSlots,
      date: this.date,
      notes: this.notes,
      time: this.time,
      durationInMinutes: this.durationInMinutes,
      testingOrganization: {
        id: this.testingOrganization.id,
        name: this.testingOrganization.name,
      },
      booked: false,
      proctor: this.proctor,
      users: this.user
        ? this.user.map((usr: any) => {
            return {
              id: usr.id,
              initials: usr.initials,
              firstName: usr.firstName,
              lastName: usr.lastName,
            };
          })
        : [],
    };
    if (this.exam && this.exam.id) {
      exam.id = this.exam.id;
    }
    this.$emit("on-submit", exam);
  }

  private onClose(): void {
    this.$emit("on-close");
  }

  public get isBooked() {
    return this.exam?.booked || false;
  }
}
