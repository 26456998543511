





































import { Component, Vue, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

@Component({
  components: { FscSimpleCard },
})
export default class StudentEducationInfoPopover extends Vue {
  public name = "StudentEducationInfoPopover";

  @Prop()
  private info!: any;

  @Prop()
  private targetId!: any;

  @Prop()
  private showCondition!: any;
}
